import { initializeApp } from "firebase/app";
import { getAnalytics, isSupported } from "firebase/analytics";

// Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDLW5Ptupb8p0qXf_daiPLUlnepbxr_5OU",
    authDomain: "portfolio-9ed3a.firebaseapp.com",
    projectId: "portfolio-9ed3a",
    storageBucket: "portfolio-9ed3a.appspot.com",
    messagingSenderId: "956111183129",
    appId: "1:956111183129:web:cad4555cd69989645ff988",
    measurementId: "G-D7N0957C22"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

let analytics = null;

// Enable analytics only if it's supported
isSupported().then((supported) => {
    if (supported) {
        analytics = getAnalytics(app);
    }
});

export { app, analytics };
